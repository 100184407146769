import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { CFV_WITH_OPTIONS } from '@/storychief/constants/Constants';
import StoryChief from '@/storychief';
import { usePrevious } from '@/storychief/hooks/index';
import CustomField from '@/storychief/components/custom-fields/CustomField';
import getInitialCustomFieldValues from '@/storychief/utils/getInitialCustomFieldValues';
import isCustomFieldVisible from '@/stories/utils/isCustomFieldVisible';
import useEntitlements from '@/entitlements/hooks/useEntitlements';

const propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      default: PropTypes.string,
      option_src: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          custom_field_id: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  isDebounceFields: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      custom_field_id: PropTypes.string.isRequired,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
  onLoading: PropTypes.func,
  language: PropTypes.string,
  type: PropTypes.oneOf(['stories', 'campaigns', 'socials', 'content-types']).isRequired,
  hideTitle: PropTypes.bool,
};
const defaultProps = {
  fields: [],
  isDebounceFields: false,
  values: [],
  readOnly: false,
  onUpdate: () => {},
  onLoading: () => {},
  language: null,
  hideTitle: false,
};

function CustomFields(props) {
  // Variables
  const {
    readOnly,
    fields,
    values,
    onUpdate,
    onLoading,
    language,
    isDebounceFields,
    type,
    hideTitle,
  } = props;

  // Hooks
  const getEntitlement = useEntitlements();
  const isEntitledToCustomFields = !getEntitlement(type) || getEntitlement(`custom-fields-${type}`);

  // State
  const [customFieldValues, setCustomFieldValues] = useState(
    getInitialCustomFieldValues(fields, values),
  );
  const prevCustomValues = usePrevious(customFieldValues);

  // Effects
  useEffect(() => {
    initState();
  }, [fields]);

  useEffect(() => {
    if (
      JSON.stringify(customFieldValues) !== JSON.stringify(prevCustomValues) &&
      prevCustomValues !== undefined
    ) {
      onUpdate(customFieldValues);
    }
  }, [customFieldValues]);

  // Functions
  function initState() {
    setCustomFieldValues(getInitialCustomFieldValues(fields, values));
  }

  function updateFieldCallback(updatedField) {
    setCustomFieldValues((currentValues) =>
      currentValues.map((cfv) => {
        if (cfv.key === updatedField.key) {
          return updatedField;
        }

        return cfv;
      }),
    );
  }

  function renderCustomField(field) {
    const value = customFieldValues.find((cv) => cv.key === field.name) || {
      key: field.name,
      value: CFV_WITH_OPTIONS.includes(field.type) ? [] : '',
    };

    if (field.type === 'select') {
      return (
        <CustomField
          disabled={readOnly}
          isDebounced={isDebounceFields}
          fieldDefinition={field}
          fieldValue={value}
          visible={isCustomFieldVisible(field, customFieldValues)}
          onUpdateCallback={updateFieldCallback}
          onLoading={onLoading}
          parentValue={
            values && field.option_parent
              ? values.find((v) => v.custom_field_id.toString() === field.option_parent.toString())
              : null
          }
          language={language}
        />
      );
    }

    return (
      <CustomField
        disabled={readOnly}
        isDebounced={isDebounceFields}
        visible={isCustomFieldVisible(field, customFieldValues)}
        fieldDefinition={field}
        fieldValue={value}
        onUpdateCallback={updateFieldCallback}
      />
    );
  }

  if (!isEntitledToCustomFields) {
    return null;
  }

  return (
    <>
      <FormGroup>
        {!hideTitle && <ControlLabel>Custom fields</ControlLabel>}
        <p className="small text-muted">
          Add additional properties.{' '}
          {!readOnly && (
            <a
              href={`${StoryChief.dashboardBasePath}/account/custom-fields`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Manage custom fields
            </a>
          )}
        </p>
      </FormGroup>
      {fields && fields.length > 0 && (
        <fieldset className="custom-fields">
          {fields.map((field) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={field.name}>{renderCustomField(field)}</div>
          ))}
        </fieldset>
      )}
    </>
  );
}

CustomFields.propTypes = propTypes;
CustomFields.defaultProps = defaultProps;

export default CustomFields;
